exports.components = {
  "component---src-pages-admin-page-js": () => import("./../../../src/pages/adminPage.js" /* webpackChunkName: "component---src-pages-admin-page-js" */),
  "component---src-pages-all-user-email-js": () => import("./../../../src/pages/allUserEmail.js" /* webpackChunkName: "component---src-pages-all-user-email-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-article-all-articles-js": () => import("./../../../src/pages/article/allArticles.js" /* webpackChunkName: "component---src-pages-article-all-articles-js" */),
  "component---src-pages-article-create-article-page-js": () => import("./../../../src/pages/article/createArticlePage.js" /* webpackChunkName: "component---src-pages-article-create-article-page-js" */),
  "component---src-pages-article-update-article-page-js": () => import("./../../../src/pages/article/updateArticlePage.js" /* webpackChunkName: "component---src-pages-article-update-article-page-js" */),
  "component---src-pages-author-count-js": () => import("./../../../src/pages/authorCount.js" /* webpackChunkName: "component---src-pages-author-count-js" */),
  "component---src-pages-blog-list-js": () => import("./../../../src/pages/blog-list.js" /* webpackChunkName: "component---src-pages-blog-list-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog-post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-bn-coaching-page-js": () => import("./../../../src/pages/bnCoachingPage.js" /* webpackChunkName: "component---src-pages-bn-coaching-page-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-nav-bar-js": () => import("./../../../src/pages/components/nav-bar.js" /* webpackChunkName: "component---src-pages-components-nav-bar-js" */),
  "component---src-pages-event-all-events-js": () => import("./../../../src/pages/event/allEvents.js" /* webpackChunkName: "component---src-pages-event-all-events-js" */),
  "component---src-pages-event-create-event-page-js": () => import("./../../../src/pages/event/createEventPage.js" /* webpackChunkName: "component---src-pages-event-create-event-page-js" */),
  "component---src-pages-event-page-js": () => import("./../../../src/pages/eventPage.js" /* webpackChunkName: "component---src-pages-event-page-js" */),
  "component---src-pages-event-update-event-page-js": () => import("./../../../src/pages/event/updateEventPage.js" /* webpackChunkName: "component---src-pages-event-update-event-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-playground-js": () => import("./../../../src/pages/mapPlayground.js" /* webpackChunkName: "component---src-pages-map-playground-js" */),
  "component---src-pages-partner-all-partners-js": () => import("./../../../src/pages/partner/allPartners.js" /* webpackChunkName: "component---src-pages-partner-all-partners-js" */),
  "component---src-pages-partner-create-partner-page-js": () => import("./../../../src/pages/partner/createPartnerPage.js" /* webpackChunkName: "component---src-pages-partner-create-partner-page-js" */),
  "component---src-pages-partner-update-partner-page-js": () => import("./../../../src/pages/partner/updatePartnerPage.js" /* webpackChunkName: "component---src-pages-partner-update-partner-page-js" */),
  "component---src-pages-playground-all-playgrounds-js": () => import("./../../../src/pages/playground/allPlaygrounds.js" /* webpackChunkName: "component---src-pages-playground-all-playgrounds-js" */),
  "component---src-pages-playground-create-playground-page-js": () => import("./../../../src/pages/playground/createPlaygroundPage.js" /* webpackChunkName: "component---src-pages-playground-create-playground-page-js" */),
  "component---src-pages-playground-update-playground-page-js": () => import("./../../../src/pages/playground/updatePlaygroundPage.js" /* webpackChunkName: "component---src-pages-playground-update-playground-page-js" */),
  "component---src-pages-privacy-page-js": () => import("./../../../src/pages/privacyPage.js" /* webpackChunkName: "component---src-pages-privacy-page-js" */),
  "component---src-pages-society-all-societies-js": () => import("./../../../src/pages/society/allSocieties.js" /* webpackChunkName: "component---src-pages-society-all-societies-js" */),
  "component---src-pages-society-create-society-page-js": () => import("./../../../src/pages/society/createSocietyPage.js" /* webpackChunkName: "component---src-pages-society-create-society-page-js" */),
  "component---src-pages-society-update-society-page-js": () => import("./../../../src/pages/society/updateSocietyPage.js" /* webpackChunkName: "component---src-pages-society-update-society-page-js" */),
  "component---src-pages-sponsor-all-sponsors-js": () => import("./../../../src/pages/sponsor/allSponsors.js" /* webpackChunkName: "component---src-pages-sponsor-all-sponsors-js" */),
  "component---src-pages-sponsor-create-sponsor-page-js": () => import("./../../../src/pages/sponsor/createSponsorPage.js" /* webpackChunkName: "component---src-pages-sponsor-create-sponsor-page-js" */),
  "component---src-pages-sponsor-update-sponsor-page-js": () => import("./../../../src/pages/sponsor/updateSponsorPage.js" /* webpackChunkName: "component---src-pages-sponsor-update-sponsor-page-js" */),
  "component---src-pages-team-all-teams-js": () => import("./../../../src/pages/team/allTeams.js" /* webpackChunkName: "component---src-pages-team-all-teams-js" */),
  "component---src-pages-team-create-team-page-js": () => import("./../../../src/pages/team/createTeamPage.js" /* webpackChunkName: "component---src-pages-team-create-team-page-js" */),
  "component---src-pages-team-update-team-page-js": () => import("./../../../src/pages/team/updateTeamPage.js" /* webpackChunkName: "component---src-pages-team-update-team-page-js" */),
  "component---src-pages-user-request-all-user-requests-js": () => import("./../../../src/pages/userRequest/allUserRequests.js" /* webpackChunkName: "component---src-pages-user-request-all-user-requests-js" */),
  "component---src-pages-user-request-answer-user-request-page-js": () => import("./../../../src/pages/userRequest/answerUserRequestPage.js" /* webpackChunkName: "component---src-pages-user-request-answer-user-request-page-js" */),
  "component---src-pages-wiki-page-js": () => import("./../../../src/pages/wikiPage.js" /* webpackChunkName: "component---src-pages-wiki-page-js" */)
}

