// gatsby-browser.js
import React from "react";
import GlobalContextProvider from "./src/context/GlobalContextProvider";
import { ArticleProvider } from "./src/hooks/use-article-context";
export const wrapRootElement = ({ element }) => {
  return (
    <ArticleProvider>
      <GlobalContextProvider>{element} </GlobalContextProvider>
    </ArticleProvider>
  );
};
